import React from "react"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"

const Spacer = ({ children, space = "1rem" }) => (
    <div style={{ marginBottom: space, marginTop: space }}>{children}</div>
)

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => (
    <p
        className="align-center"
        style={{ marginBottom: ".8rem", display: "block" }}
    >
        {children}
    </p>
)
const UText = ({ children }) => (
    <p
        className="align-center"
        style={{
            marginBottom: ".3rem",
            textDecoration: "underline",
            display: "block"
        }}
    >
        {children}
    </p>
)
const I = ({ children }) => (
    <p
        className="align-center"
        style={{ fontStyle: "italic", display: "block" }}
    >
        {children}
    </p>
)

const options = {
    renderMark: {
        [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        [MARKS.UNDERLINE]: text => <UText underline>{text}</UText>,
        [MARKS.ITALIC]: text => <I>{text}</I>
    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
        [BLOCKS.EMBEDDED_ASSET]: node => {
            return (
                <Spacer>
                    <img
                        style={{ width: "100%" }}
                        src={node.data.target.fixed.src}
                        alt="img"
                    />
                </Spacer>
            )
        },
        [BLOCKS.UL_LIST]: (node, children) => (
            <Spacer>
                <ul>{children}</ul>
            </Spacer>
        ),
        [BLOCKS.OL_LIST]: (node, children) => (
            <Spacer>
                <ol>{children}</ol>
            </Spacer>
        ),
        [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
        [BLOCKS.HEADING_1]: (node, children) => (
            <Spacer>
                <h1>{children}</h1>
            </Spacer>
        ),
        [BLOCKS.HEADING_2]: (node, children) => (
            <Spacer>
                <h2>{children}</h2>
            </Spacer>
        ),
        [BLOCKS.HEADING_3]: (node, children) => (
            <Spacer>
                <h3>{children}</h3>
            </Spacer>
        ),
        [BLOCKS.HEADING_4]: (node, children) => (
            <Spacer>
                <h4>{children}</h4>
            </Spacer>
        ),
        [BLOCKS.HEADING_5]: (node, children) => (
            <Spacer>
                <h5>{children}</h5>
            </Spacer>
        ),
        [BLOCKS.HEADING_6]: (node, children) => (
            <Spacer>
                <h6>{children}</h6>
            </Spacer>
        ),
        [BLOCKS.EMBEDDED_ENTRY]: (node, children) => (
            <p style={{ fontSize: "inherit" }}>{children}</p>
        ),
        [INLINES.HYPERLINK]: node => {
            const href = typeof node.data.uri === "string" ? node.data.uri : ""
            return (
                <a
                    href={href}
                    style={{ fontSize: "inherit", color: "#631726" }}
                >
                    {node.content[0].value}
                </a>
            )
        }
    }
}
export { options }
