import React from "react"

import { renderRichText } from "gatsby-source-contentful/rich-text"
import M from "moment"
import { options } from "../../utils/richTextOptions"
import "./style.css"

import Layout from "../../components/layout"

const Detail = ({ pageContext }) => {
    const { data, isEvent } = pageContext

    return (
        <Layout withStrokes>
            <div className="main--container">
                <h1 className="main-title">{data?.title}</h1>
                <p className="subText">
                    {isEvent
                        ? data.location
                        : `Article written by ${data.author}`}
                </p>
                <p className="subText">
                    {isEvent
                        ? data.category
                        : M(data.createdAt).format("Do MMMM YYYY")}
                </p>
                {renderRichText(data.content, options)}
            </div>
        </Layout>
    )
}

export default Detail
